import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtl from 'stylis-plugin-rtl';
import { useRouter } from 'next/router';

const options = {
  rtl: { key: 'css-ar', stylisPlugins: [rtl] },
  ltr: { key: 'css-en' }
};

const RTLProvider: React.FC = ({ children }: any) => {
  const { locale } = useRouter();
  const dir = locale == 'ar' ? 'rtl' : 'ltr';
  const cache = createCache(options[dir]);
  // const cache = createCache({ key: 'css-ar', stylisPlugins: [rtl] });
  return <CacheProvider value={cache} children={children} />;
};

export default RTLProvider;
